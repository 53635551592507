<template>
  <div class="searchBar">
    <el-form
      :inline="true"
      :model="searchForm"
      ref="searchForm"
      label-position="left"
    >
      <el-form-item label="事件编号" prop="code" class="searchBox">
        <el-input v-model="searchForm.code" placeholder="请输入事件编号" />
      </el-form-item>
      <el-form-item label="泄漏物名称" prop="title" class="searchBox">
        <el-input v-model="searchForm.title" placeholder="请输入泄漏物名称" />
      </el-form-item>
      <el-form-item label="事件地址" prop="address" class="searchBox">
        <el-input v-model="searchForm.address" placeholder="请输入事件地址" />
      </el-form-item>
      <el-form-item label="事件备注" prop="text" class="searchBox">
        <el-input v-model="searchForm.text" placeholder="请输入事件备注" />
      </el-form-item>
      <el-form-item label="更新时间" prop="createTime" class="searchBox">
        <el-input
          v-model="searchForm.createTime"
          placeholder="请输入更新时间"
        />
      </el-form-item>
      <el-form-item class="searchBox">
        <el-button type="primary" class="btn" @click="queryProject"
          >查询</el-button
        >
        <el-button class="btn" @click="resetFrom">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
  <div class="content">
    <div class="projectMsg">
      <div class="projectMsgMain">
        <div class="activeBar">
          <!-- <el-input
            v-model="inputContent"
            class="choose"
            placeholder="请输入项目名称"
          ></el-input> -->
          <!-- <div class="btn search" @click="queryProject">查询</div> -->
          <div class="btn add" @click="openProjectDialog">新增</div>
          <div class="btn del" @click="delProjectDialogVisible">删除</div>
        </div>
        <div>
          <el-table
            :data="projectData"
            border
            style="width: 100%"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" class="selection">
            </el-table-column>
            <el-table-column prop="code" label="事件编号" class="projectNumber">
            </el-table-column>
            <el-table-column
              prop="title"
              label="泄漏物质名称"
              class="projectName"
            >
            </el-table-column>
            <el-table-column
              prop="address"
              label="事件地址"
              class="projectAddress"
            >
            </el-table-column>
            <el-table-column
              prop="text"
              label="事件备注"
              class="projectRemarks"
            >
            </el-table-column>
            <el-table-column
              prop="createTime"
              label="更新时间"
              class="projectRemarks"
            >
            </el-table-column>

            <el-table-column label="操作" class="projectEdit">
              <template #default="scope">
                <el-button text @click="operation(scope.row)">
                  <span style="color: #c3a35d; text-decoration: underline"
                    >编辑</span
                  >
                </el-button>
                <!-- <el-button text @click="DialogVisible(scope.row)">
                                      <span style="color: #C3A35D; text-decoration: underline;">设备绑定</span>
                                  </el-button> -->
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-config-provider :locale="locale">
          <div class="block">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              v-model:currentPage="currentPage"
              :page-sizes="[10, 30, 50, 100]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next"
              :total="total"
              background
            >
            </el-pagination>
          </div>
        </el-config-provider>
      </div>
    </div>
    <!-- 项目新增对话框 -->
    <el-dialog
      v-model="addProjectDialogVisible"
      title="新增项目信息"
      width="700"
    >
      <!-- <el-form-item label="项目编号">
                  <el-input v-model="addProjectNumberInput" placeholder="请输入新的项目编号" style="margin-bottom: 10px;" />
              </el-form-item> -->
      <el-form-item label="泄漏物质名称" label-width="85">
        <el-input
          v-model="addEventNameInput"
          placeholder="请输入新的泄漏物质名称"
          style="margin-bottom: 10px"
        />
      </el-form-item>
      <el-form-item label="事件地址" label-width="85">
        <el-input
          v-model="addEventAddressInput"
          placeholder="请输入新的事件地址"
          style="margin-bottom: 10px"
        />
      </el-form-item>
      <el-form-item label="泄漏场景" label-width="85">
        <el-input
          v-model="addEventSenceInput"
          placeholder="道路、河流、建筑"
          style="margin-bottom: 10px"
        />
      </el-form-item>
      <el-form-item label="预估泄露量" label-width="85">
        <el-input
          v-model="addEventNumberInput"
          placeholder="请输入预估泄露量"
          style="margin-bottom: 10px"
        />
      </el-form-item>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="addProjectDialogVisible = false">取消</el-button>
          <el-button type="primary" :plain="true" @click="addProjectVisible">
            确定
          </el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 项目编辑对话框 -->
    <el-dialog
      v-model="operationDialogVisible"
      title="修改项目信息"
      width="768px"
    >
      <!-- <el-form-item label="项目编号">
                  <el-input v-model="form.mcode" placeholder="请输入新的项目编号" style="margin-bottom: 10px;" />
              </el-form-item> -->
      <el-form-item label="泄漏物质名称" label-width="85">
        <el-input
          v-model="form.remark_name"
          placeholder="请输入新的泄漏物质名称"
          style="margin-bottom: 10px"
        />
      </el-form-item>
      <el-form-item label="事件地址" label-width="85">
        <el-input
          v-model="form.address"
          placeholder="请输入新的事件地址"
          style="margin-bottom: 10px"
        />
      </el-form-item>
      <el-form-item label="泄漏场景" label-width="85">
        <el-input
          v-model="form.remark_address"
          placeholder="道路、河流、建筑"
          style="margin-bottom: 10px"
        />
      </el-form-item>
      <el-form-item label="预估泄露量" label-width="85">
        <el-input
          v-model="form.remark_value"
          placeholder="请输入预估泄露量"
          style="margin-bottom: 10px"
        />
      </el-form-item>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="operationDialogVisible = false">取消</el-button>
          <el-button type="primary" :plain="true" @click="operationVisible">
            确定
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
  
  <script>
import zhCn from "element-plus/lib/locale/lang/zh-cn";
import apiFun from "../network/api";
import { cityData } from "../assets/common/js/cityData";

export default {
  name: "userCenter",
  data() {
    return {
      pageNum: 1,
      pageSize: 10,
      total: 10,
      addProjectDialogVisible: false,
      operationDialogVisible: false,
      bindingDialogVisible: false,
      // 项目信息修改
      addProjectNumberInput: "",
      addEventNameInput: "",
      addEventAddressInput: "",
      addEventNumberInput: "",
      addEventSenceInput: "",
      // 项目信息修改
      projectNumberInput: "",
      projectNameInput: "",
      projectAddressInput: "",
      projectUserInput: "",
      projectRemarksInput: "",
      parentMid: "",
      // 设备信息绑定
      projectMid: "",
      equipmentMacInput: "",
      locale: zhCn,
      currentPage: 1,
      equipmentOptions: [
        {
          equipmentValue: "1",
          label: "网络设备",
        },
        {
          equipmentValue: "2",
          label: "器具柜设备",
        },
      ],
      projectData: [],
      projectValue: "",
      equipmentValue: "",
      userValue: "",
      deathOrlife: false,
      // 省市区数据
      //   form: {},
      cityData: [],
      cityList: [],
      areaList: [],
      inputContent: "",
      projectList: {
        mname: "",
      },
      merChantList: [],
      mname: "",
      nowDate: "",
      remark: {
        remark_name: "",
        remark_address: "",
        remark_value: "",
      },
      form: {},
      searchForm: {
        code: "",
        title: "",
        text: "",
        createTime: "",
        address: "",
      },
    };
  },
  // 页面挂载时调用方法
  mounted: function () {
    //需要触发的函数
    this.queryProject();
    this.setNowTimes();

    // 省市区数据获取
    // this.getCityData(window.Glob.GETCITY_URL, {
    //   key: window.Glob.GETCITY_KEY,
    //   subdistrict: 3,
    // });
  },
  methods: {
    setNowTimes() {
      //获取当前时间
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, "0"); // 月份是从0开始的，所以需要加1
      const day = String(now.getDate()).padStart(2, "0");
      const hours = String(now.getHours()).padStart(2, "0");
      const minutes = String(now.getMinutes()).padStart(2, "0");
      const seconds = String(now.getSeconds()).padStart(2, "0");
      this.nowDate = `${year}${month}${day}${hours}${minutes}${seconds}`;
      console.log("this.nowDate", this.nowDate);
    },
    // 选择框事件
    handleSelectionChange(val) {
      this.selected = val;
      console.log(this.selected);
    },
    // 修改页范围
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryProject();
      console.log(`共 ${val} 条数据`);
    },
    // 修改页码
    handleCurrentChange(val) {
      this.pageNum = val;
      this.queryProject();
      console.log(`当前页: ${val}`);
    },
    projectSearch() {
      console.log("1");
    },
    // 项目列表新增
    addProjectVisible() {
      if (
        this.addEventNumberInput == "" ||
        this.addEventNameInput == "" ||
        this.addEventAddressInput == "" ||
        this.addEventSenceInput == ""
      ) {
        this.$message({
          message: "请完善项目信息！",
          type: "warning",
        });
      } else {
        let addEventData = {};

        // if (JSON.parse(localStorage.user_mid) !== null) {
        //   addEventData.isParent = "1";
        // } else {
        //   addEventData.isParent = "0";
        // }
        // addEventData.pageNum = this.pageNum
        // ad d.pageSize = this.pageSize
        addEventData.title = this.addEventSenceInput + this.addEventNameInput;
        addEventData.address = this.addEventAddressInput;
        addEventData.code = this.mname + this.nowDate;
        this.remark.remark_address = this.addEventSenceInput;
        this.remark.remark_name = this.addEventNameInput;
        this.remark.remark_value = this.addEventNumberInput;
        addEventData.remark = JSON.stringify(this.remark);
        addEventData.mid = localStorage.getItem("mid");
        console.log("addEventData", addEventData);
        apiFun.postEventSave(addEventData).then((res) => {
          this.queryProject();
        });
        (this.addProjectDialogVisible = false),
          this.$message({
            message: "项目信息新建成功!",
            type: "success",
          });
      }
    },
    getMerchant() {
      apiFun
        .getPorject({
          MId: localStorage.getItem("mid"),
        })
        .then((res) => {
          debugger;
          if (res.code == 200) {
            if (
              res.data.find((item) => item.mid == localStorage.getItem("mid"))
            ) {
              this.mname = res.data.find(
                (item) => item.mid == localStorage.getItem("mid")
              ).mname;
            }
          }
        });
    },
    //点取消关闭项目新增弹框
    openProjectDialog() {
      (this.addEventAddressInput = ""),
        (this.addEventNameInput = ""),
        (this.addEventSenceInput = ""),
        (this.addEventNumberInput = ""),
        (this.addProjectDialogVisible = true);
    },
    // 事件删除
    delProjectDialogVisible() {
      this.$confirm("确定删除吗", "项目列表删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let eventNumbers = [];
          // console.log("project====", project);
          if (!this.selected) {
            this.$message({
              message: "请选择要删除的事件!",
              type: "error",
            });
          } else {
            this.selected.forEach((project) => eventNumbers.push(project.gid));
            apiFun.delEventSave(eventNumbers).then((res) => {
              this.$message({
                message: "事件列表删除成功!",
                type: "success",
              });
              console.log(res);
              this.queryProject();
            });
            return;
          }
        })
        .catch(() => {
          this.$message({
            message: "取消删除",
            grouping: true,
            type: "message",
          });
          return;
        });
    },
    // 事件列表获取
    queryProject() {
      this.getMerchant();
      let projectList = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        MID: localStorage.getItem("mid"),
        sort: 0,
      };
      // if (JSON.parse(localStorage.user_mid) !== null) {
      //   projectList.isParent = "1";
      // } else {
      //   projectList.isParent = "0";
      // }
      if (this.searchForm.code.length != 0) {
        projectList.code = this.searchForm.code;
      }
      if (this.searchForm.title.length != 0) {
        projectList.title = this.searchForm.title;
      }
      if (this.searchForm.address.length != 0) {
        projectList.address = this.searchForm.address;
      }
      if (this.searchForm.text.length != 0) {
        let match = this.searchForm.text.match(
          /在(.*)处，发生了(.*)泄漏，预估泄漏量约为(.*)/
        );

        if (match) {
          let remark = {
            remark_name: match[2], // 泄漏名称
            remark_address: match[1], // 泄漏地点
            remark_value: match[3], // 泄漏量
          };

          let remarkString = JSON.stringify(remark);
          console.log(remarkString); // 输出转换后的 remark 字符串
        
        projectList.remark = remarkString;
        }
      }
      if (this.searchForm.createTime.length != 0) {
        projectList.createTime = this.searchForm.createTime;
      }
      apiFun.getEventList(projectList).then((res) => {
        this.total = res.data.total;
        // this.projectData = res.data.list;
        this.projectData = res.data.list.map((item) => {
          console.log('item.remark====',item.remark)
          let txt = JSON.parse(item.remark);
          let text =
            "在" +
            txt.remark_address +
            "处，发生了" +
            txt.remark_name +
            "泄漏，预估泄漏量约为" +
            txt.remark_value;
          return {
            ...item,
            text,
          };
        });

        this.merChantList = res.data.list.map((item) => ({
          text: item.mname,
          value: item.mid,
        }));
        console.log("this.merChantList", this.merChantList);
      });
    },
    // 事件编辑弹窗
    operation(item) {
      console.log(item);
      this.operationDialogVisible = true;
      //   this.form = item;
      this.form.remark;
      let formRemark = JSON.parse(item.remark);
      this.form.remark_address = formRemark.remark_address;
      this.form.remark_name = formRemark.remark_name;
      this.form.remark_value = formRemark.remark_value;
      this.form.address = item.address;
      this.form.code = item.code;
      this.form.gid = item.gid;
    },
    // 事件编辑修改
    operationVisible() {
      if (
        this.form.remark_address == "" ||
        this.form.remark_name == "" ||
        this.form.remark_value == "" ||
        this.form.address == ""
      ) {
        this.$message({
          message: "请完善项目信息！",
          type: "warning",
        });
      } else {
        let eventData = {};
        eventData.title = this.form.remark_address + this.form.remark_name;
        eventData.address = this.form.address;
        eventData.code = this.form.code;
        this.remark.remark_address = this.form.remark_address;
        this.remark.remark_name = this.form.remark_name;
        this.remark.remark_value = this.form.remark_value;
        eventData.remark = JSON.stringify(this.remark);
        eventData.mid = localStorage.getItem("mid");
        eventData.gid = this.form.gid;
        apiFun.postEventSave(eventData).then((res) => {
          console.log("事件信息修改成功！");
          console.log(res);
          this.queryProject();
          (this.operationDialogVisible = false),
            this.$message({
              message: "事件信息修改成功!",
              type: "success",
            });
        });
      }
    },
    // 重置搜索表单
    resetFrom() {
      this.$refs.searchForm.resetFields();
      this.queryProject();
    },
  },
};
</script>
  
  <style scoped>
/* 搜索栏 */
.searchBar {
  width: 2180px;
  height: auto;
  margin: 10px 0 20px 0;
  max-width: 100%;
  border-radius: 5px;
  background: #fff;
  box-shadow: 5px 2px 5px 1px rgba(0, 0, 0, 0.2);
}

.searchBar .searchBox {
  width: 600 px;
  margin: 10px 30px;
}
/* 搜索栏-按钮样式 */
.searchBar .btn {
  height: 40px;
  width: 80px;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
}
/* 新增弹窗 */
.optional {
  display: inline-block;
  margin-left: 10px;
  font-size: 14px;
  color: #bbbbbb;
}

/* 右侧内容栏 */
.content {
  width: 2180;
  min-height: 710px;
  height: auto;
  background-color: #ffffff;
  box-shadow: 5px 2px 5px 1px rgba(0, 0, 0, 0.2);
}

.content .projectMsg {
  padding: 20px;
}

/* 搜索栏 */
.activeBar {
  margin-bottom: 20px;
}

.activeBar .txt {
  margin-right: 20px;
  font-size: 14px;
  color: #333333;
}

.activeBar .choose {
  width: 300px;
  height: 40px;
  border-radius: 0;
}

.el-input__wrapper {
  width: 300px;
  height: 40px;
  border-radius: 0;
  border: 1px solid #d6d8dc;
}

.el-select-dropdown__item {
  height: 40px;
  line-height: 40px;
  color: #333333;
}

/* 按钮样式 */
.activeBar .btn {
  display: inline-block;
  cursor: pointer;
  width: 80px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  color: #f1f2f6;
  background-color: #c3a35d;
}

.activeBar .search {
  margin-right: 20px;
}

.activeBar .add {
  margin-right: 20px;
  border-radius: 5px;
  background-color: #409eff;
}

.activeBar .del {
  border-radius: 5px;
  background-color: #f46d6c;
}

/* 表格样式 */
.el-table {
  font-size: 16px;
  color: #333333;
}

/* 表格宽度 */
.selection {
  width: 50px;
}

.projectNumber {
  width: 290px;
}

.projectName {
  width: 290px;
}

.projectAddress {
  width: 290px;
}

.projectUser {
  width: 280px;
}

.projectRemarks {
  width: 290px;
}

.projectEdit {
  width: 290px;
}
</style>
  
  <style>
/* 搜索框样式 */
.el-input__wrapper {
  width: 300px;
  height: 40px;
  border-radius: 0;
}

/* 表格样式 */
.el-table .cell {
  padding: 0;
  text-align: center;
}

.el-table__cell {
  padding: 0;
  height: 50px;
}

.el-table__row .el-table__cell:last-child {
  cursor: pointer;
  color: #c3a35d;
  /* text-decoration: underline; */
}

.el-table__body-wrapper .el-table-column--selection > .cell,
.el-table__header-wrapper .el-table-column--selection > .cell {
  display: flex !important;
  justify-content: center;
}

/* 分页器样式 */
.block {
  float: right;
  margin-top: 20px;
  margin-bottom: 20px;
}
.el-select {
  width: 100% !important;
}
</style>
  
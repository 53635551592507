<template>
   <div class="searchBar">
    <el-form
      :inline="true"
      :model="searchForm"
      ref="searchForm"
      label-position="left"
    >
      <el-form-item label="项目名称" prop="mname" class="searchBox">
        <el-input v-model="searchForm.mname" placeholder="请输入项目名称" />
      </el-form-item>
      <el-form-item label="项目编号" prop="mid" class="searchBox">
        <el-input v-model="searchForm.mid" placeholder="请输入项目编号" />
      </el-form-item>
      <el-form-item label="项目地址" prop="address" class="searchBox">
        <el-input v-model="searchForm.address" placeholder="请输入项目地址" />
      </el-form-item>
      <el-form-item label="项目描述" prop="mdesc" class="searchBox">
        <el-input v-model="searchForm.mdesc" placeholder="请输入项目描述" />
      </el-form-item>
      <el-form-item class="searchBox">
        <el-button type="primary" class="btn"  @click="queryProject">查询</el-button>
        <el-button class="btn" @click="resetFrom">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
  <div class="content">
    <div class="projectMsg">
      <div class="projectMsgMain">
        <div class="activeBar">
          <!-- <el-input
            v-model="inputContent"
            class="choose"
            placeholder="请输入项目名称"
          ></el-input> -->
          <!-- <div class="btn search" @click="queryProject">查询</div> -->
          <div class="btn add" @click="openProjectDialog">新增</div>
          <div class="btn del" @click="delProjectDialogVisible">删除</div>
        </div>
        <div>
          <el-table
            :data="projectData"
            border
            style="width: 100%"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" class="selection">
            </el-table-column>
            <el-table-column prop="mid" label="项目编号" class="projectNumber">
            </el-table-column>
            <el-table-column prop="mname" label="项目名称" class="projectName">
            </el-table-column>
            <el-table-column
              prop="address"
              label="项目地址"
              class="projectAddress"
            >
            </el-table-column>
            <el-table-column
              prop="mdesc"
              label="项目描述"
              class="projectRemarks"
            >
            </el-table-column>
            <el-table-column label="子项目" class="projectRemarks">
              <template #default="{ row }">
                {{ row.children.length ? "有" : "暂无" }}
              </template>
            </el-table-column>
            <el-table-column label="操作" class="projectEdit">
              <template #default="scope">
                <el-button text @click="operation(scope.row)">
                  <span style="color: #c3a35d; text-decoration: underline"
                    >编辑</span
                  >
                </el-button>
                <!-- <el-button text @click="DialogVisible(scope.row)">
                                    <span style="color: #C3A35D; text-decoration: underline;">设备绑定</span>
                                </el-button> -->
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-config-provider :locale="locale">
          <div class="block">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              v-model:currentPage="currentPage"
              :page-sizes="[10, 30, 50, 100]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next"
              :total="total"
              background
            >
            </el-pagination>
          </div>
        </el-config-provider>
      </div>
    </div>
    <!-- 项目新增对话框 -->
    <el-dialog
      v-model="addProjectDialogVisible"
      title="新增项目信息"
      width="700"
    >
      <!-- <el-form-item label="项目编号">
                <el-input v-model="addProjectNumberInput" placeholder="请输入新的项目编号" style="margin-bottom: 10px;" />
            </el-form-item> -->
      <el-form-item label="项目名称">
        <el-input
          v-model="addProjectNameInput"
          placeholder="请输入新的项目名称"
          style="margin-bottom: 10px"
        />
      </el-form-item>
      <el-form-item label="项目地址">
        <el-input
          v-model="addProjectAddressInput"
          placeholder="请输入新的项目地址"
          style="margin-bottom: 10px"
        />
      </el-form-item>
      <el-form-item label="上级项目">
        <el-select
          v-model="parentMid"
          placeholder="请选择"
          size="large"
          style="width: 240px; margin-bottom: 10px"
        >
          <el-option
            v-for="item in merChantList"
            :key="item.value"
            :label="item.text"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="项目描述">
        <el-input
          v-model="addProjectRemarksInput"
          placeholder="请输入新的项目描述"
        />
      </el-form-item>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="addProjectDialogVisible = false">取消</el-button>
          <el-button type="primary" :plain="true" @click="addProjectVisible">
            确定
          </el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 项目编辑对话框 -->
    <el-dialog
      v-model="operationDialogVisible"
      title="修改项目信息"
      width="768px"
    >
      <!-- <el-form-item label="项目编号">
                <el-input v-model="form.mcode" placeholder="请输入新的项目编号" style="margin-bottom: 10px;" />
            </el-form-item> -->
      <el-form-item label="项目名称">
        <el-input
          v-model="form.mname"
          placeholder="请输入新的项目名称"
          style="margin-bottom: 10px"
        />
      </el-form-item>
      <el-form-item label="项目地址">
        <el-input
          v-model="form.address"
          placeholder="请输入新的项目地址"
          style="margin-bottom: 10px"
        />
      </el-form-item>
      <el-form-item label="上级项目">
        <el-select
          v-model="form.parentMid"
          placeholder="请选择"
          size="large"
          style="width: 240px; margin-bottom: 10px"
        >
          <el-option
            v-for="item in merChantList"
            :key="item.value"
            :label="item.text"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="项目描述">
        <el-input v-model="form.mdesc" placeholder="请输入新的项目描述" />
      </el-form-item>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="operationDialogVisible = false">取消</el-button>
          <el-button type="primary" :plain="true" @click="operationVisible">
            确定
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import zhCn from "element-plus/lib/locale/lang/zh-cn";
import apiFun from "../network/api";
import { cityData } from "../assets/common/js/cityData";

export default {
  name: "userCenter",
  data() {
    return {
      pageNum: 1,
      pageSize: 10,
      total: 10,
      addProjectDialogVisible: false,
      operationDialogVisible: false,
      bindingDialogVisible: false,
      // 项目信息修改
      addProjectNumberInput: "",
      addProjectNameInput: "",
      addProjectAddressInput: "",
      addProjectUserInput: "",
      addProjectRemarksInput: "",
      // 项目信息修改
      projectNumberInput: "",
      projectNameInput: "",
      projectAddressInput: "",
      projectUserInput: "",
      projectRemarksInput: "",
      parentMid: "",
      // 设备信息绑定
      projectMid: "",
      equipmentMacInput: "",
      locale: zhCn,
      currentPage: 1,
      equipmentOptions: [
        {
          equipmentValue: "1",
          label: "网络设备",
        },
        {
          equipmentValue: "2",
          label: "器具柜设备",
        },
      ],
      projectData: [],
      projectValue: "",
      equipmentValue: "",
      userValue: "",
      deathOrlife: false,
      // 省市区数据
      form: {},
      cityData: [],
      cityList: [],
      areaList: [],
      inputContent: "",
      projectList: {
        mname: "",
      },
      merChantList: [],
      searchForm: {
        mname: "",
        address: "",
        mid: "",
        mdesc: "",

      },
    };
  },
  // 页面挂载时调用方法
  mounted: function () {
    //需要触发的函数
    this.queryProject();
  },
  methods: {
    // 选择框事件
    handleSelectionChange(val) {
      this.selected = val;
      console.log(this.selected);
    },
    // 修改页范围
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryProject();
      console.log(`共 ${val} 条数据`);
    },
    // 修改页码
    handleCurrentChange(val) {
      this.pageNum = val;
      this.queryProject();
      console.log(`当前页: ${val}`);
    },
    projectSearch() {
      console.log("1");
    },
    // 项目列表新增
    addProjectVisible() {
      if (
        // this.parentMid == "" ||
        this.addProjectNameInput == "" ||
        this.addProjectAddressInput == "" ||
        this.addProjectRemarksInput == ""
      ) {
        this.$message({
          message: "请完善项目信息！",
          type: "warning",
        });
      } else {
        let addProjectData = {};

        // if (JSON.parse(localStorage.user_mid) !== null) {
        //   addProjectData.isParent = "1";
        // } else {
        //   addProjectData.isParent = "0";
        // }
        // addProjectData.pageNum = this.pageNum
        // addProjectData.pageSize = this.pageSize
        addProjectData.mname = this.addProjectNameInput;
        addProjectData.address = this.addProjectAddressInput;
        addProjectData.mdesc = this.addProjectRemarksInput;
        addProjectData.parentMid = this.parentMid;
        console.log(this.parentMid);
        console.log("addProjectData", addProjectData);
        apiFun.postPorjectAdd(addProjectData).then((res) => {
          console.log("项目新建提交成功！");
          console.log(res);
          this.queryProject();
        });
        (this.addProjectDialogVisible = false),
          this.$message({
            message: "项目信息新建成功!",
            type: "success",
          });
      }
    },
    //点取消关闭项目新增弹框
    openProjectDialog() {
      (this.addProjectAddressInput = ""),
        (this.parentMid = ""),
        (this.addProjectNameInput = ""),
        (this.addProjectRemarksInput = ""),
        (this.addProjectDialogVisible = true);
    },
    // 项目删除
    delProjectDialogVisible() {
      this.$confirm("确定删除吗", "项目列表删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let mids = [];
          if (!this.selected) {
            this.$message({
              message: "请选择要删除的项目!",
              type: "error",
            });
          } else {
            this.selected.forEach((project) => mids.push(project.mid));
            console.log('11111',mids);
            let data = new URLSearchParams();
            data.append("mids", mids.join(","));
            apiFun.postPorjectDelete(data).then((res) => {
              this.$message({
                message: "项目列表删除成功!",
                type: "success",
              });
              console.log(res);
              this.queryProject();
            });
            return;
          }
        })
        .catch(() => {
          this.$message({
            message: "取消删除",
            grouping: true,
            type: "message",
          });
          return;
        });
    },
    // 项目列表获取
    queryProject() {
      let projectList = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        userId: localStorage.getItem("userId"),
      };
      // if (JSON.parse(localStorage.user_mid) !== null) {
      //   projectList.isParent = "1";
      // } else {
      //   projectList.isParent = "0";
      // }
      if (this.searchForm.mname.length != 0) {
        projectList.MName = this.searchForm.mname
        
      }
      if (this.searchForm.mdesc.length != 0) {
        projectList.MDesc = this.searchForm.mdesc
        
      }
      if (this.searchForm.address.length != 0) {
        projectList.address = this.searchForm.address
        
      }
      if (this.searchForm.mid.length != 0) {
        projectList.MId = this.searchForm.mid
        
      }
      apiFun.getPagingPorject(projectList).then((res) => {
        console.log("项目列表获取成功！");
        console.log(res);
        this.projectData = res.data.list;
        this.total = res.data.total;
        console.log('projectData',this.projectData);
        this.merChantList = res.data.list.map((item) => ({
          text: item.mname,
          value: item.mid,
        }));
        console.log("this.merChantList", this.merChantList);
      });
    },
    // 项目编辑弹窗
    operation(item) {
      this.operationDialogVisible = true;
      console.log('item',item)
      this.form = item;
    },
    // 项目编辑修改
    operationVisible() {
      if (
        this.form.parentMid == "" ||
        this.form.mname == "" ||
        this.form.address == "" ||
        this.form.mdesc == ""
      ) {
        this.$message({
          message: "请完善项目信息！",
          type: "warning",
        });
      } else {
        let projectData = {};
        projectData = { ...this.form };

        // if (this.form.mname !== "") {
        //   projectData.mname = this.form.mname;
        // }
        // if (this.form.address !== "") {
        //   projectData.address = this.form.address;
        // }

        // if (this.form.mdesc !== "") {
        //   projectData.mdesc = this.form.mdesc;
        // }
        // if (JSON.parse(localStorage.user_mid) !== "") {
        //   projectData.isParent = "1";
        // } else {
        //   projectData.isParent = "0";
        // }
        (projectData.parentMid = this.form.parentMid),
          apiFun.postPorjectEdit(projectData).then((res) => {
            (this.operationDialogVisible = false),
              this.$message({
                message: "项目信息修改成功!",
                type: "success",
              });
            console.log(res);
            this.queryProject();
          });
      }
    },
      // 重置搜索表单
      resetFrom() {
      this.$refs.searchForm.resetFields();
      this.queryProject();
    },
    
  },
};
</script>

<style scoped>
/* 搜索栏 */
.searchBar {
  width: 2180px;
  height: auto;
  margin: 10px 0 20px 0;
  max-width: 100%;
  border-radius: 5px;
  background: #fff;
  box-shadow: 5px 2px 5px 1px rgba(0, 0, 0, 0.2);
}

.searchBar .searchBox {
  width: 600 px;
  margin: 10px 30px;
}
/* 搜索栏-按钮样式 */
.searchBar .btn {
  height: 40px;
  width: 80px;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
}
/* 新增弹窗 */
.optional {
  display: inline-block;
  margin-left: 10px;
  font-size: 14px;
  color: #bbbbbb;
}

/* 右侧内容栏 */
.content {
  width: 2180;
  min-height: 710px;
  height: auto;
  background-color: #ffffff;
  box-shadow: 5px 2px 5px 1px rgba(0, 0, 0, 0.2);
}

.content .projectMsg {
  padding: 20px;
}

/* 搜索栏 */
.activeBar {
  margin-bottom: 20px;
}

.activeBar .txt {
  margin-right: 20px;
  font-size: 14px;
  color: #333333;
}

.activeBar .choose {
  width: 300px;
  height: 40px;
  border-radius: 0;
}

.el-input__wrapper {
  width: 300px;
  height: 40px;
  border-radius: 0;
  border: 1px solid #d6d8dc;
}

.el-select-dropdown__item {
  height: 40px;
  line-height: 40px;
  color: #333333;
}

/* 按钮样式 */
.activeBar .btn {
  display: inline-block;
  cursor: pointer;
  width: 80px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  color: #f1f2f6;
  background-color: #c3a35d;
}

.activeBar .search {
  margin-right: 20px;
}

.activeBar .add {
  margin-right: 20px;
  border-radius: 5px;
  background-color: #409eff;
}

.activeBar .del {
  border-radius: 5px;
  background-color: #f46d6c;
}

/* 表格样式 */
.el-table {
  font-size: 16px;
  color: #333333;
}

/* 表格宽度 */
.selection {
  width: 50px;
}

.projectNumber {
  width: 290px;
}

.projectName {
  width: 290px;
}

.projectAddress {
  width: 290px;
}

.projectUser {
  width: 280px;
}

.projectRemarks {
  width: 290px;
}

.projectEdit {
  width: 290px;
}
</style>

<style>
/* 搜索框样式 */
.el-input__wrapper {
  width: 300px;
  height: 40px;
  border-radius: 0;
}

/* 表格样式 */
.el-table .cell {
  padding: 0;
  text-align: center;
}

.el-table__cell {
  padding: 0;
  height: 50px;
}

.el-table__row .el-table__cell:last-child {
  cursor: pointer;
  color: #c3a35d;
  /* text-decoration: underline; */
}

.el-table__body-wrapper .el-table-column--selection > .cell,
.el-table__header-wrapper .el-table-column--selection > .cell {
  display: flex !important;
  justify-content: center;
}

/* 分页器样式 */
.block {
  float: right;
  margin-top: 20px;
  margin-bottom: 20px;
}
.el-select {
  width: 100% !important;
}
</style>

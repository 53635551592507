<template>
  <div class="searchBar">
    <el-form
      :inline="true"
      :model="searchForm"
      ref="searchForm"
      label-position="left"
    >
      <el-form-item label="登陆账号" prop="username" class="searchBox">
        <el-input v-model="searchForm.username" placeholder="请输入登陆账号" />
      </el-form-item>
      <el-form-item label="昵称" prop="nickName" class="searchBox">
        <el-input v-model="searchForm.nickName" placeholder="请输入显示昵称" />
      </el-form-item>
      <el-form-item label="电子邮箱" prop="email" class="searchBox">
        <el-input v-model="searchForm.email" placeholder="请输入电子邮箱" />
      </el-form-item>
      <el-form-item label="真实姓名" prop="name" class="searchBox">
        <el-input v-model="searchForm.name" placeholder="请输入真实姓名" />
      </el-form-item>
      <el-form-item label="手机号" prop="telephone" class="searchBox">
        <el-input v-model="searchForm.telephone" placeholder="请输入手机号" />
      </el-form-item>
      <el-form-item label="联系地址" prop="address" class="searchBox">
        <el-input v-model="searchForm.address" placeholder="请输入联系地址" />
      </el-form-item>
      <!-- <el-form-item label="绑定权限" prop="roleName" class="searchBox">
        <el-input v-model="searchForm.roleName" placeholder="请输入绑定权限" />
      </el-form-item> -->
      <el-form-item label="绑定项目" prop="text" class="searchBox">
        <el-input v-model="searchForm.text" placeholder="请输入绑定项目" />
      </el-form-item>
      <el-form-item class="searchBox">
        <el-button type="primary" class="btn" @click="queryProject"
          >查询</el-button
        >
        <el-button class="btn" @click="resetFrom">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
  <div class="content">
    <div class="projectMsg">
      <div class="projectMsgMain">
        <div class="activeBar">
          <!-- <el-input
            v-model="inputContent"
            class="choose"
            placeholder="请输入登陆账号"
          ></el-input> -->
          <!-- <div class="btn search" @click="queryProject">查询</div> -->
          <!-- <el-button type="primary" @click="openProjectDialog" class="btn"
          >新增</el-button
        > -->
          <div class="btn add" type="primary" @click="openProjectDialog">
            新增
          </div>
          <div class="btn del" @click="delProjectDialogVisible">删除</div>
        </div>
        <div>
          <el-table
            :data="projectData"
            border
            style="width: 100%"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" class="selection">
            </el-table-column>
            <el-table-column
              prop="username"
              label="登陆账号"
              class="projectNumber"
            >
            </el-table-column>
            <el-table-column
              prop="nickName"
              label="显示昵称"
              class="projectName"
            >
            </el-table-column>
            <el-table-column
              prop="email"
              label="电子邮箱"
              class="projectAddress"
            >
            </el-table-column>
            <el-table-column
              prop="name"
              label="真实姓名"
              class="projectRemarks"
            >
            </el-table-column>
            <el-table-column
              prop="telephone"
              label="绑定手机"
              class="projectRemarks"
            >
            </el-table-column>
            <!-- <el-table-column
             :prop="item.userInfos[0].address"
              label="联系地址"
              class="projectRemarks"
            >
            </el-table-column> -->
            <el-table-column label="联系地址" class="projectRemarks">
              <template #default="{ row }">
                {{ row.userInfos && row.userInfos.length ? row.userInfos[0].address :"" }}
              </template>
            </el-table-column>

            <el-table-column label="权限绑定" class="projectRemarks">
              <template #default="{ row }">
                {{ row.roles && row.roles.length ? row.roles[0].roleName : "" }}
              </template>
            </el-table-column>
            <el-table-column
              prop="mtext"
              label="绑定项目"
              class="projectRemarks"
            >
            </el-table-column>

            <el-table-column label="操作" class="projectEdit">
              <template #default="scope">
                <el-button text @click="operation(scope.row)">
                  <span style="color: #c3a35d; text-decoration: underline"
                    >编辑</span
                  >
                </el-button>
                <!-- <el-button text @click="DialogVisible(scope.row)">
                                      <span style="color: #C3A35D; text-decoration: underline;">设备绑定</span>
                                  </el-button> -->
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-config-provider :locale="locale">
          <div class="block">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              v-model:currentPage="currentPage"
              :page-sizes="[10, 30, 50, 100]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next"
              :total="total"
              background
            >
            </el-pagination>
          </div>
        </el-config-provider>
      </div>
    </div>
    <!-- 人员新增对话框 -->
    <el-dialog
      v-model="addProjectDialogVisible"
      title="新增人员信息"
      width="700"
    >
      <!-- <el-form-item label="人员编号">
                  <el-input v-model="addProjectNumberInput" placeholder="请输入新的人员编号" style="margin-bottom: 10px;" />
              </el-form-item> -->
      <el-form-item label="登陆账号">
        <el-input
          v-model="userFrom.username"
          placeholder="请输入新的登陆账号"
          style="margin-bottom: 10px"
        />
      </el-form-item>
      <el-form-item label="显示昵称">
        <el-input
          v-model="userFrom.nickName"
          placeholder="请输入新的显示昵称"
          style="margin-bottom: 10px"
        />
      </el-form-item>
      <el-form-item label="登陆密码">
        <el-input
          v-model="userFrom.password"
          placeholder="请输入新的登陆密码"
          style="margin-bottom: 10px"
          type="password"
          show-password
        />
      </el-form-item>
      <el-form-item label="确认密码">
        <el-input
          v-model="confirmPassword"
          placeholder="请确认新的密码"
          style="margin-bottom: 10px"
          type="password"
          show-password
        />
      </el-form-item>
      <el-form-item label="电子邮箱">
        <el-input
          v-model="userFrom.email"
          placeholder="请输入新的电子邮箱"
          style="margin-bottom: 10px"
        />
      </el-form-item>
      <el-form-item label="真实姓名">
        <el-input
          v-model="userFrom.name"
          placeholder="请输入新的真实姓名"
          style="margin-bottom: 10px"
        />
      </el-form-item>
      <el-form-item label="绑定手机">
        <el-input
          v-model="userFrom.telephone"
          placeholder="请输入新的绑定手机"
          style="margin-bottom: 10px"
        />
      </el-form-item>
      <el-form-item label="联系地址">
        <el-input
          v-model="userFrom.address"
          placeholder="请输入新的联系地址"
          style="margin-bottom: 10px"
        />
      </el-form-item>

      <el-form-item label="权限绑定">
        <el-select
          v-model="userFrom.roleId"
          placeholder="请选择"
          size="large"
          style="width: 240px; margin-bottom: 10px"
        >
          <el-option
            v-for="item in roleList"
            :key="item.value"
            :label="item.text"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="项目绑定">
        <el-select
          v-model="userFrom.mid"
          placeholder="请选择"
          size="large"
          style="width: 240px; margin-bottom: 10px"
        >
          <el-option
            v-for="item in merChantList"
            :key="item.value"
            :label="item.text"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="addProjectDialogVisible = false">取消</el-button>
          <el-button type="primary" :plain="true" @click="addProjectVisible">
            确定
          </el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 人员编辑对话框 -->
    <el-dialog
      v-model="operationDialogVisible"
      title="修改人员信息"
      width="768px"
    >
      <!-- <el-form-item label="人员编号">
                  <el-input v-model="form.mcode" placeholder="请输入新的人员编号" style="margin-bottom: 10px;" />
              </el-form-item> -->
      <el-form-item label="登陆账号">
        <el-input
          v-model="form.username"
          placeholder="请输入新的登陆账号"
          style="margin-bottom: 10px"
        />
      </el-form-item>
      <el-form-item label="显示昵称">
        <el-input
          v-model="form.nickName"
          placeholder="请输入新的显示昵称"
          style="margin-bottom: 10px"
        />
      </el-form-item>
      <!-- <el-form-item label="登陆密码">
        <el-input
          v-model="form.password"
          placeholder="请输入新的登陆密码"
          style="margin-bottom: 10px"
        />
      </el-form-item> -->
      <!-- <el-form-item label="确认密码">
        <el-input
          v-model="confirmPasswordEdit"
          placeholder="请确认新的密码"
          style="margin-bottom: 10px"
        />
      </el-form-item> -->
      <el-form-item label="电子邮箱">
        <el-input
          v-model="form.email"
          placeholder="请输入新的电子邮箱"
          style="margin-bottom: 10px"
        />
      </el-form-item>
      <el-form-item label="真实姓名">
        <el-input
          v-model="form.name"
          placeholder="请输入新的真实姓名"
          style="margin-bottom: 10px"
        />
      </el-form-item>
      <el-form-item label="绑定手机">
        <el-input
          v-model="form.telephone"
          placeholder="请输入新的绑定手机"
          style="margin-bottom: 10px"
        />
      </el-form-item>
      <el-form-item label="联系地址">
        <el-input
          v-model="form.userInfos[0].address"
          placeholder="请输入新的联系地址"
          style="margin-bottom: 10px"
        />
      </el-form-item>

      <el-form-item label="权限绑定">
        <el-select
          v-model="form.roles[0].roleId"
          placeholder="请选择"
          size="large"
          style="width: 240px; margin-bottom: 10px"
        >
          <el-option
            v-for="item in roleList"
            :key="item.value"
            :label="item.text"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="项目绑定">
        <el-select
          v-model="form.mid"
          placeholder="请选择"
          size="large"
          style="width: 240px; margin-bottom: 10px"
        >
          <el-option
            v-for="item in merChantList"
            :key="item.value"
            :label="item.text"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="operationDialogVisible = false">取消</el-button>
          <el-button type="primary" :plain="true" @click="operationVisible">
            确定
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
  
  <script>
import zhCn from "element-plus/lib/locale/lang/zh-cn";
import apiFun from "../network/api";
import { encode } from "../assets/common/js/encryption";

export default {
  name: "userCenter",
  data() {
    return {
      pageNum: 1,
      pageSize: 10,
      total: 10,
      addProjectDialogVisible: false,
      operationDialogVisible: false,
      bindingDialogVisible: false,
      // 人员信息修改
      addProjectNumberInput: "",
      addProjectNameInput: "",
      addProjectAddressInput: "",
      addProjectUserInput: "",
      addProjectRemarksInput: "",
      // 人员信息修改
      projectNumberInput: "",
      projectNameInput: "",
      projectAddressInput: "",
      projectUserInput: "",
      projectRemarksInput: "",
      confirmPassword: "",

      // 设备信息绑定
      projectMid: "",
      equipmentMacInput: "",
      locale: zhCn,
      currentPage: 1,
      equipmentOptions: [
        {
          equipmentValue: "1",
          label: "网络设备",
        },
        {
          equipmentValue: "2",
          label: "器具柜设备",
        },
      ],
      projectData: [],
      projectValue: "",
      equipmentValue: "",
      userValue: "",
      deathOrlife: false,
      // 省市区数据
      form: {},
      cityData: [],
      cityList: [],
      areaList: [],
      inputContent: "",
      merChantList: [],
      userFrom: {
        name: "",
        telephone: "",
        username: "",
        nickName: "",
        email: "",
        mid: 0,
        roleId: "",
        address: "",
        mid: "",
      },
      form: {},
      midName: "",
      roleList: [],
      searchForm: {
        username: "",
        nickName: "",
        email: "",
        telephone: "",
        name: "",
        address: "",
        mid: "",
        text: "",
        roleName: "",
      },
    };
  },
  created() {
    // this.queryProject();
  },
  // 页面挂载时调用方法
  mounted: function () {
    let that = this;
    //list
    this.getMerChantList();
    //需要触发的函数
    // setTimeout(() => {
    //   that.queryProject();
    // }, 200);
  },
  methods: {
    // 手机号验证
    isValidPhoneNumber(phoneNumber) {
      const regex = /^1[3456789]\d{9}$/;
      return regex.test(phoneNumber);
    },
    // 邮箱验证
    validateEmail(email) {
      var regex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return regex.test(String(email).toLowerCase());
    },
    // 选择框事件
    handleSelectionChange(val) {
      this.selected = val;
      console.log(this.selected);
    },
    // 修改页范围
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryProject();
      console.log(`共 ${val} 条数据`);
    },
    // 修改页码
    handleCurrentChange(val) {
      this.pageNum = val;
      this.queryProject();
      console.log(`当前页: ${val}`);
    },
    projectSearch() {
      console.log("1");
    },
    //密码转码
    encodepassword(password, eles = []) {
      eles.push(password);
      return encode("0x12", eles);
    },
    // 人员列表新增
    addProjectVisible() {
      if (
        this.userFrom.username == "" ||
        this.userFrom.nickName == "" ||
        this.userFrom.password == "" ||
        this.confirmPassword == "" ||
        this.userFrom.email == "" ||
        this.userFrom.name == "" ||
        this.userFrom.telephone == "" ||
        this.userFrom.address == "" ||
        this.userFrom.roleId == "" ||
        this.userFrom.mid == ""
      ) {
        this.$message({
          message: "请完善人员信息！",
          type: "warning",
        });
      } else if (
        this.userFrom.password.length < 6 ||
        this.confirmPassword.length < 6
      ) {
        this.$message({
          message: "密码长度至少为6！",
          type: "warning",
        });
      } else if (this.userFrom.password != this.confirmPassword) {
        this.$message({
          message: "密码不一致！",
          type: "warning",
        });
      } else if (this.userFrom.telephone.length != 11) {
        this.$message({
          message: "手机号应为11位！",
          type: "warning",
        });
      } else if (!this.isValidPhoneNumber(this.userFrom.telephone)) {
        this.$message({
          message: "请输入正确的手机号",
          type: "warning",
        });
      } else if (this.userFrom.roleId == 1) {
        this.$message({
          message: "不允许创建超级管理员",
          type: "warning",
        });
      } else {
        let addProjectData = {};

        // if (JSON.parse(localStorage.user_mid) !== null) {
        //   addProjectData.isParent = "1";
        // } else {
        //   addProjectData.isParent = "0";
        // }
        // addProjectData.pageNum = this.pageNum
        // addProjectData.pageSize = this.pageSize

        addProjectData = { ...this.userFrom };
        console.log("addProjectData====", addProjectData);
        addProjectData.roleIds = [this.userFrom.roleId];
        addProjectData.password = this.encodepassword(this.userFrom.password);

        apiFun.postRoleAdd(addProjectData).then((res) => {
          console.log("人员新建提交成功！");

          this.queryProject();
        });
        (this.addProjectDialogVisible = false),
          this.$message({
            message: "人员信息新建成功!",
            type: "success",
          });
      }
    },
    //点取消关闭人员新增弹框
    openProjectDialog() {
      (this.userFrom = {
        name: "",
        telephone: "",
        username: "",
        nickName: "",
        email: "",
        mid: 0,
        roleName: "",
        address: "",
        mid: "",
      }),
        (this.confirmPassword = "")((this.addProjectDialogVisible = true));
    },
    // 人员删除
    delProjectDialogVisible() {
      this.$confirm("确定删除吗", "人员列表删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let ids = [];
          if (!this.selected) {
            this.$message({
              message: "请选择要删除的人员!",
              type: "error",
            });
          } else {
            this.selected.forEach((project) => ids.push(project.userId));
            console.log(ids);
            let data = new URLSearchParams();
            data.append("ids", ids.join(","));
            console.log("data===", data);
            apiFun.postRoleDelete(data).then((res) => {
              if (res.code == 200) {
                this.$message({
                  message: "人员列表删除成功!",
                  type: "success",
                });
              } else {
                this.$message({
                  message: res.msg,
                  type: "success",
                });
              }
              this.queryProject();
            });
            return;
          }
        })
        .catch(() => {
          this.$message({
            message: "取消删除",
            grouping: true,
            type: "message",
          });
          return;
        });
    },
    // 重置搜索表单
    resetFrom() {
      this.$refs.searchForm.resetFields();
      this.queryProject();
    },
    // 人员列表获取

    queryProject() {
      let peopleList = {
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        sort: 0,
        // username: "",
      };
      let merChantList = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        userId: localStorage.getItem("userId"),
      };
      let roleList = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        userId: localStorage.getItem("userId"),
      };

      if (this.searchForm.username.length != 0) {
        peopleList.username = this.searchForm.username;
        console.log("address");
      }
      if (this.searchForm.nickName.length != 0) {
        peopleList.nickName = this.searchForm.nickName;
        console.log("aaaa", peopleList.nickName);
      }
      if (this.searchForm.address.length != 0) {
        peopleList.address = this.searchForm.address;
      }
      if (this.searchForm.email.length != 0) {
        peopleList.email = this.searchForm.email;
      }
      if (this.searchForm.name.length != 0) {
        peopleList.name = this.searchForm.name;
      }
      if (this.searchForm.telephone.length != 0) {
        peopleList.telephone = this.searchForm.telephone;
      }

      if (this.searchForm.roleName.length != 0) {
        peopleList["roles%5B0%5D.roleName"] = this.searchForm.roleName;
      }
      console.log("this.merChantList", this.merChantList);
      let selectitem = this.merChantList.filter(
        (item) => item.text === this.searchForm.text
      );
      this.searchForm.mid = selectitem.length > 0 ? selectitem[0].value : "";
      console.log("this.searchForm.mid ", this.searchForm.mid);
      if (this.searchForm.mid.length != 0) {
        peopleList.MId = this.searchForm.mid;
      }
      apiFun.getRoleMsg(peopleList).then((res) => {
        // console.log("人员列表获取成功！");

        // 遍历人员id
        res.data.list.forEach((item) => {
          this.merChantList.forEach((citem) => {
            if (item.mid == citem.value) {
              item.mtext = citem.text;
            }
          });
        });
        this.projectData = res.data.list;
        this.total = res.data.total;
      });

      //新增下拉列表数据渲染
      // apiFun.getPagingPorject(merChantList).then((res) => {
      //   this.merChantList = res.data.list.map((item) => ({
      //     text: item.mname,
      //     value: item.mid,
      //   }));
      // });

      apiFun.getRoleList(roleList).then((res) => {
        this.roleList = res.data.list.map((item) => ({
          text: item.description,
          value: item.roleId,
        }));
      });
    },

    // 获取merchant列表
    getMerChantList() {
      let parma = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        userId: localStorage.getItem("userId"),
      };
      apiFun.getPagingPorject(parma).then((res) => {
        this.merChantList = res.data.list.map((item) => ({
          text: item.mname,
          value: item.mid,
        }));
        this.queryProject();
      });
    },

    // 人员编辑弹窗
    operation(item) {
      this.operationDialogVisible = true;
      this.form = item;
    },
    // 人员编辑修改
    operationVisible() {
      console.log("一点击确认");
      if (
        this.form.username == "" ||
        this.form.nickName == "" ||
        this.form.email == "" ||
        this.form.name == "" ||
        this.form.telephone == "" ||
        this.form.address == "" ||
        this.form.roleId == "" ||
        this.form.mid == ""
      ) {
        this.$message({
          message: "请完善人员信息！",
          type: "warning",
        });
      } else if (this.form.telephone.length != 11) {
        this.$message({
          message: "手机号应为11位！",
          type: "warning",
        });
      } else if (!this.isValidPhoneNumber(this.form.telephone)) {
        this.$message({
          message: "请输入正确的手机号",
          type: "warning",
        });
      } else {
        let projectData = {};
        projectData = { ...this.form };
        projectData.roleIds = [this.form.roles[0].roleId];
        projectData.address = this.form.userInfos[0].address;
        delete projectData.roles;
        projectData.opType = 2;
        // if (JSON.parse(localStorage.user_mid) !== "") {
        //   projectData.isParent = "1";
        // } else {
        //   projectData.isParent = "0";
        // }
        (projectData.mid = this.form.mid),
          apiFun.postModifyUserMsg(projectData).then((res) => {
            (this.operationDialogVisible = false),
              this.$message({
                message: "人员信息修改成功!",
                type: "success",
              });
            console.log(res);
            this.queryProject();
          });
      }
    },
  },
};
</script>
  
  <style scoped>
/* 搜索栏 */
.searchBar {
  width: 2180px;
  height: auto;
  margin: 10px 0 20px 0;
  max-width: 100%;
  border-radius: 5px;
  background: #fff;
  box-shadow: 5px 2px 5px 1px rgba(0, 0, 0, 0.2);
}

.searchBar .searchBox {
  width: 600 px;
  margin: 10px 30px;
}
/* 搜索栏-按钮样式 */
.searchBar .btn {
  height: 40px;
  width: 80px;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
}
/* 新增弹窗 */
.optional {
  display: inline-block;
  margin-left: 10px;
  font-size: 14px;
  color: #bbbbbb;
}

/* 右侧内容栏 */
.content {
  width: 2180;
  min-height: 710px;
  height: auto;
  background-color: #ffffff;
  box-shadow: 5px 2px 5px 1px rgba(0, 0, 0, 0.2);
}

.content .projectMsg {
  padding: 20px;
}

/* 搜索栏 */
.activeBar {
  margin-bottom: 20px;
}

.activeBar .txt {
  margin-right: 20px;
  font-size: 14px;
  color: #333333;
}

.activeBar .choose {
  width: 300px;
  height: 40px;
  border-radius: 0;
}

.el-input__wrapper {
  width: 300px;
  height: 40px;
  border-radius: 0;
  border: 1px solid #d6d8dc;
}

.el-select-dropdown__item {
  height: 40px;
  line-height: 40px;
  color: #333333;
}

/* 按钮样式 */
.activeBar .btn {
  display: inline-block;
  cursor: pointer;
  width: 80px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  color: #f1f2f6;
  background-color: #c3a35d;
}

.activeBar .search {
  margin-right: 20px;
}

.activeBar .add {
  margin-right: 20px;
  border-radius: 5px;
  background-color: #409eff;
}

.activeBar .del {
  border-radius: 5px;
  background-color: #f46d6c;
}

/* 表格样式 */
.el-table {
  font-size: 16px;
  color: #333333;
}

/* 表格宽度 */
.selection {
  width: 50px;
}

.projectNumber {
  width: 290px;
}

.projectName {
  width: 290px;
}

.projectAddress {
  width: 290px;
}

.projectUser {
  width: 280px;
}

.projectRemarks {
  width: 290px;
}

.projectEdit {
  width: 290px;
}
</style>
  
  <style>
/* 搜索框样式 */
.el-input__wrapper {
  width: 300px;
  height: 40px;
  border-radius: 0;
}

/* 表格样式 */
.el-table .cell {
  padding: 0;
  text-align: center;
}

.el-table__cell {
  padding: 0;
  height: 50px;
}

.el-table__row .el-table__cell:last-child {
  cursor: pointer;
  color: #c3a35d;
  /* text-decoration: underline; */
}

.el-table__body-wrapper .el-table-column--selection > .cell,
.el-table__header-wrapper .el-table-column--selection > .cell {
  display: flex !important;
  justify-content: center;
}

/* 分页器样式 */
.block {
  float: right;
  margin-top: 20px;
  margin-bottom: 20px;
}
.el-select {
  width: 100% !important;
}
</style>
  